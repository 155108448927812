<template>
	<div class="ce-media-image">
		<component
			:is="file.properties.link ? 'nav-link' : 'div'"
			:to="file.properties.link"
			:enableImageZoom="enableImageZoom"
		>
			<figure>
				<nuxt-img
					format="webp"
					:src="
						file.publicUrl.replace(
							`${this.$config.ipxImageBaseUrl}`,
							''
						)
					"
					:alt="file.properties.alternative || ''"
					:title="file.properties.title || title"
					:sizes="{ sm: '30vw', md: '50vw', lg: '1500px' }"
					preload
				/>
			</figure>

			<figcaption v-if="file.properties.description">
				{{ file.properties.description }}
			</figcaption>
		</component>
	</div>
</template>

<script>
export default {
	name: 'MediaImage',
	props: {
		file: {
			type: Object,
			required: true,
			default: () => {},
		},
		enableImageZoom: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			title: '',
		}
	},
}
</script>
