<template>
	<div
		:class="`formulate-input-element formulate-input-element--${context.type}`"
		:data-type="context.type"
		:data-multiple="attributes && attributes.multiple !== undefined"
	>
		<v-select
			:options="options"
			class="vue-select"
			v-model="context.model"
			v-bind="attributes"
		>
			<template #search="{attributes, events}">
				<input
					v-bind="attributes"
					v-on="events"
					class="form-select"
				/>
			</template>
		</v-select>
	</div>
</template>

<script>
	import Vue from 'vue';
	import vSelect from 'vue-select';
	import OpenIndicator from '../select/OpenIndicator';

	Vue.component('v-select', vSelect);
	vSelect.props.components.default = () => ({ OpenIndicator });

	export default {
		name: 'FormulateInputSelect',
		props: {
			context: {
				type: Object,
				required: true
			},
		}
	}

</script>
<style lang="scss">
	@import "vue-select/src/scss/vue-select.scss";
	@import '~@/assets/scss/common';
	$vue-select-border-radius: 50em !default;
	$vue-select-dropdown-border-radius: 25px !default;
	$vue-select-dropdown-placeholder-color: color(primary,light) !default;
	$vue-select-form-select-background-color: #fff !default;
	$transition-timing-function: cubic-bezier(1.0, 0.5, 0.8, 1.0) !default;
	$transition-duration: .5s !default;

	.vue-select .form-select {
		background: $vue-select-form-select-background-color;
		border: none;
		height: 50px;

		&:focus {
			box-shadow: none;
		}
	}

	.vue-select .vs__dropdown-menu {
		color: $form-select-color;
		border-bottom-right-radius: $vue-select-dropdown-border-radius;
		border-bottom-left-radius: $vue-select-dropdown-border-radius;
		box-shadow: none;
		border-bottom-color: $form-select-color;
		border-left-color: $form-select-color;
		border-right-color: $form-select-color;
	}

	.vue-select .vs__dropdown-toggle {
		padding: 0;
	}

	.vue-select .vs__dropdown-option {
		font-size: $small-font-size;
		color: $form-select-color;

		&:hover {
			cursor: pointer;
		}
	}

	.vs__dropdown-option--highlight {
		background: color(secondary, light);
		color: $form-select-color;
	}

	.vue-select .form-select::placeholder {
		color: $form-select-color;
	}

	.vue-select .vs__dropdown-toggle {
		border-radius: $vue-select-border-radius;
		border: 1px solid $form-select-color;
	}

	.vue-select.vs--open {
		.vs__dropdown-toggle {
			border-top-right-radius: $vue-select-dropdown-border-radius;
			border-top-left-radius: $vue-select-dropdown-border-radius;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		::placeholder {
			color: $vue-select-dropdown-placeholder-color;
		}
	}

	.vue-select.vs--single {
		&.vs--open .vs__selected {
			color: $vue-select-dropdown-placeholder-color;
			opacity: 1;
		}
	}


	.vue-select .vs__open-indicator {
		fill: color(secondary, dark);
	}

	.vue-select .vs__clear {
		display: none;
	}

	.vue-select .vs__selected {
		font-size: $small-font-size;
		position: absolute;
		left: 0;
		margin: 12px;
		color: $input-placeholder-color;
	}

	.vue-select .vs__actions {
		position: absolute;
		right: 0;
		padding: 10px;

		svg {
			width: 30px;
		}
	}

	.form-select::-webkit-search-cancel-button {
		display: none;
	}

	.form-select::-webkit-search-decoration,
	.form-select::-webkit-search-results-button,
	.form-select::-webkit-search-results-decoration,
	.form-select::-ms-clear {
		display: none;
	}

	.vs__fade-enter-active,
	.vs__fade-leave-active {
		pointer-events: none;
		transition: height $transition-duration $transition-timing-function;
	}

	.vs__fade-enter,
	.vs__fade-leave-to {
		height: 0;
	}
</style>
