<template functional>
  <div 
    class="ce-bullets"
    :data-aos="content_animation"
		data-aos-anchor-placement="center-bottom"
		data-aos-duration="1000"
  >
    <ce-header v-bind="props" />
    <ul
		:is="props.bulletsType === 1 ? 'ol' : 'ul'"
		v-if="props.bulletsType === 0 || props.bulletsType === 1"
		:class="[`vertical-list`]"
    >
      <li v-for="(el, index) in props.bodytext" :key="index">
        {{ el }}
      </li>
    </ul>
    <dl class="definition" v-else>
      <template v-for="(el, index) in props.bodytext">
        <dt :key="index">
          {{ el[0] }}
        </dt>
        <dd v-if="el[1]" :key="index">
          {{ el[1] }}
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import CeBullets from '~typo3/components/content/elements/CeBullets.vue'

export default {
	extends: CeBullets,
  props: {
  content_animation: {
    type: String,
    required: false,
    default: ''
  }
}
}
</script>
