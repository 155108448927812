<template>
	<div class="ce-image">
		<ce-header v-bind="$props" />
		<media-gallery
			:gallery="gallery"
			:enableImageZoom="enableImageZoom"
			:data-aos="content_animation"
			data-aos-anchor-placement="center-bottom"
			data-aos-duration="1000"
		/>
	</div>
</template>

<script>

import MediaGallery from '../media/Gallery.vue';
export default {
	name: 'Images',
	extends: MediaGallery,
	components: {
		MediaGallery
	},
	props: {
		gallery: {
			type: Object,
			required: true,
			default: () => {}
		},
		id: {
			type: Number,
			required: true,
			default: 0
		},
		/**
		 * Type of CE / TYPO3 CType
		 */
		type: {
			type: String,
			required: true,
			default: 'header'
		},
		/**
		 * Header content / TYPO3 header
		 */
		header: {
			type: String,
			required: false,
			default: ''
		},
		/**
		 * Header Type / TYPO3 header_layout
		 */
		headerLayout: {
			type: Number,
			required: false,
			default: 0
		},
		/**
		 * Header alignment / TYPO3 header_position
		 */
		headerPosition: {
			type: String,
			required: false,
			default: ''
		},
		/**
		 * Date of publication / TYPO3 date
		 */
		date: {
			type: Number,
			required: false,
			default: 0
		},
		/**
		 * Header link / TYPO3 header_link
		 */
		headerLink: {
			type: [String, Object],
			required: false,
			default: ''
		},
		/**
		 * Subheader / TYPO3 subheader
		 */
		subheader: {
			type: String,
			required: false,
			default: ''
		},
		enableImageZoom: {
			type: Boolean,
			required: false,
			default: false
		},
		content_animation: {
			type: String,
			required: false,
			default: ''
		}
	},
}

</script>
