<template>
	<div class="switch mt-2 mb-2">
	<p>{{tx_mask_etracker_check_box_text}}</p>
		<label class="et-switch">
			<input
				type="checkbox"
				data-tld="www.isd-service.de"
				data-language="null"
				id="trackingAllowed"
			>
			<span class="et-slider"></span>
		</label>
	<p class="mt-2">Weitere Informationen zum Datenschutz bei&nbsp;etracker&nbsp;finden Sie&nbsp;<a href="https://www.etracker.com/datenschutz/" target="_blank" rel="noreferrer noopener">hier</a>.&nbsp;</p>
	</div>
</template>

<script>
export default {
	props: {
		mask_etracker_check: {
			type: Object
		},
		tx_mask_etracker_check_box_text: {
			type: String
		}
	},
	mounted() {
		function initTrackingCheckBox(id) {
			var checkBox = document.getElementById(id);
			checkBox.checked = _etracker.isTrackingEnabled();
			checkBox.onclick = function () {
			var tld = this.getAttribute('data-tld');
			this.checked ? _etracker.enableTracking(tld) : _etracker.disableTracking(tld);
		    };
		}
		initTrackingCheckBox('trackingAllowed');
	},
}
</script>


<style lang="scss">
.et-switch {
	position: relative;
	display: inline-block;
	line-height: 1;
	width: 40px;
	height: 20px;
}

.et-switch input {
	display: none;
}

.et-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #666666;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 34px;
}

.et-slider::before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 2px;
	bottom: 3px;
	background-color: white;
	transition: .4s;
	border-radius: 50%;
}

input#trackingAllowed:checked + .et-slider {
	background-image: linear-gradient(180deg, #ff9021, #ff4a5a);
	background-color: #ff9021;
}

input#trackingAllowed:checked + .et-slider::before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
	left: 2px;
	background-color: white;
}

input#trackingAllowed + span::after {
	content: "Meine Besuchsdaten fließen nicht in die Web-Analyse ein.";
	position: absolute;
	left: 50px;
	width: auto;
	white-space: nowrap;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

input#trackingAllowed:checked + span::after {
	content: "Meine Besuchsdaten fließen in die Web-Analyse ein.";
}

@media only screen and (max-width: 800px) {
	input#trackingAllowed + span::after {
		white-space: normal;
		width: calc(100vw - 150px);
	}
}
</style>
