<template>
	<div>
		<slot>
			<input
				v-model="zipcode"
				ref="autocomplete"
				type="text"
				v-bind:placeholder="placeholder"
			/>
		</slot>
	</div>
</template>

<script>
import { geocode, filterComponents } from '~/plugins/geocode.js'
export default {
	props: {
		country: { type: String },
		placeholder: { type: String, default: 'zipcode' },
	},
	data: function () {
		return {
			zipcode: '',
		}
	},

	watch: {
		zipcode(newVal) {},
	},

	methods: {
		processZip() {
			this.$gmapApiPromiseLazy().then(() => {
				var element = this.$scopedSlots.default()[0].context.$refs
					.autocomplete

				var options = {
					types: ['(regions)'],
					fields: ['geometry', 'address_components'],
				}

				if (this.country) {
					options.componentRestrictions = {
						country: this.country,
					}
				}

				this.autocomplete = new google.maps.places.Autocomplete(
					element,
					options
				)

				this.autocomplete.addListener('place_changed', () => {
					let place = this.autocomplete.getPlace()

					// could happen when you search for an empty string
					if (typeof place.geometry.location === 'undefined') {
						console.log("couldn't fetch a location")

						this.$toast('Der Ort konnte nicht gefunden werden.', {
							timeout: 2000,
						})

						return false
					}

					var latlng = {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					}

					this.$store.dispatch('setSearchStringDistance', latlng)

					geocode(latlng).then((results) => {
						var zipcode = filterComponents(results, 'postal_code')
						var city = filterComponents(results, 'locality')
						var country = filterComponents(results, 'country')

						var location = {
							place,
							latlng,
							city,
							zipcode,
							country,
						}

						this.$emit('selected', location)
					})

					this.$set(this.$data, 'zipcode', location.zipcode)
				})
			})
		},
	},
	mounted() {
		this.processZip()
	},
}
</script>
<style lang="scss">
.pac-icon {
	width: 0;
	background-image: none;
}
</style>
