<template>
	<div :class="`formulate-input-element formulate-input-element--${context.type}`" :data-type="context.type">

		<Zipcode @selected="zipcodeSelected" country="DE">
			<input
				country="DE"
				type="text"
				ref="autocomplete"
				placeholder="bitte Stadt oder PLZ angeben"
				v-model="context.model"
			>
		</Zipcode>

	</div>
</template>

<script>
import Zipcode from './Zipcode.vue';
export default {
	components: {
		Zipcode,
	},
	props: {
		context: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			zipcode: '',
			location: '',
		}
	},
	watch: {
	},
	computed: {
		model() {
			return this.context.model
		},
	},
	methods: {
		zipcodeSelected(location) {
			this.$set(this.$data, 'zipcode', location.zipcode);
			this.context.rootEmit('zip-selected', location)
			// this.$set(this.$data, 'location', JSON.stringify({
			//     city: location.city,
			//     zipcode: location.zipcode,
			//     country: location.country,
			// }));

			// console.log(location);
		},
	}
}
</script>
