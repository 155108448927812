<template functional>
	<client-only>
		<div class="responsive-video">
			<video
				v-bind="data.attrs"
				:class="[data.class, data.staticClass]"
				controls
			>
				<source
					:src="props.file.publicUrl"
					:height="props.file.properties.dimensions.height"
					:width="props.file.properties.dimensions.width"
					:type="props.file.properties.mimeType"
				/>
			</video>
		</div>
	</client-only>
</template>
<script>
export default {
	name: 'MediaVideo',
	functional: true,
	props: {
		file: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
