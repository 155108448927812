<template>
	<div>
		<ce-header v-bind="$props"/>
		<blockquote class="blockquote text-center mt-6">
		<html-parser :content="bodytext"/>
			<footer class="blockquote-footer">
				{{blockquoteAuthorName}}
				<span>{{blockquoteAuthorAdditional}}</span>
			</footer>
		</blockquote>
	</div>
</template>

<script>
	import CeText from '~typo3/components/content/elements/CeText.vue'
	export default {
		extends: CeText,
		props: {
			blockquoteAuthorName: {
				type: String,
				required: true,
				default: ''
			},
			blockquoteAuthorAdditional: {
				type: String,
				required: true,
				default: ''
			}
		}
	}

</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/common';
	$blockquote--color: color(primary, base) !default;
	$blockquote--cite--color: color(primary, light) !default;
	$blockquote-footer-font-size: rem(22) !default;
	$blockquote-footer-margin: rem(60) !default;
	$blockquote-footer-icon: quote-right !default;
	$blockquote-footer-icon-font-size: rem(75) !default;
	$blockquote-footer-icon-color: #d6eaf9 !default;

	.blockquote {
		font-style: italic;
		color: $blockquote--color;
	}

	.blockquote-footer {
		position: relative;
		color: $blockquote--cite--color;
		font-weight: 800;
		font-style: normal;
		font-size: $blockquote-footer-font-size;
		margin: $blockquote-footer-margin auto;
		display: inline-flex;
		flex-direction: column;
		text-align: left;

		&:before {
			position: absolute;
			@include icon($blockquote-footer-icon);
			font-size: $blockquote-footer-icon-font-size;
			color: $blockquote-footer-icon-color;
			left: -55px;
			top: -25px;
			z-index: -1;

		}
	}

	.blockquote span {
		color: $blockquote--cite--color;
		font-weight: 800;
		font-style: normal;
		font-size: rem(15);
		color: $blockquote--color;
	}
</style>

