<template>
	<div class="tx-kesearch-pi1">
		<VueTypeaheadBootstrap
			v-model="searchQuery"
			:data="data"
			:serializer="(resultrows) => resultrows.title_text"
			@hit="selectedResult = $event"
			:screen-reader-text-serializer="
				(resultrows) => resultrows.title_text
			"
			placeholder="Suchbegriff"
			:background-variant-resolver="
				(resultrows) =>
					resultrows.filePreviewId % 2 == 0 ? 'light' : 'dark'
			"
			@input="keSearchResults"
			:minMatchingChars="2"
			:showAllResults="true"
			:showOnFocus="true"
		>
			<template slot="append">
				<button
					class="btn btn-tertiary"
					type="submit"
					id="button-search"
				>
					<span class="d-none d-md-block">
						{{ ButtonText }}
					</span>
				</button>
			</template>

			<template slot="suggestion" slot-scope="{ data }">
				<div class="ke-results-autcomplete-list">
					<h5 class="mb-2 ke-results-title" v-html="data.title"></h5>
					<p
						class="ke-results-teaser"
						:inner-html.prop="data.teaser | truncateText(100)"
					></p>
				</div>
			</template>
		</VueTypeaheadBootstrap>
	</div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { debounce } from 'lodash'

export default {
	components: {
		VueTypeaheadBootstrap,
	},
	data() {
		return {
			ButtonText: 'Jetzt suchen',
			selectedResult: null,
			data: [],
		}
	},
	computed: {
		searchQuery: {
			get() {
				return this.$store.state.searchQueryString
			},
			set(value) {
				this.$store.dispatch('setSearchQueryString', value)
			},
		},
	},

	methods: {
		keSearchResults: debounce(function () {
			fetch(
				`${this.$config.keSearchResultsUrl}/suche.html?tx_kesearch_pi1[headless_ce]=1601&tx_kesearch_pi1[sword]=${this.searchQuery}&no_cache=1`
			)
				.then((response) => {
					return response.json()
				})
				.then((data) => {
					this.data = data.resultrows
				})
		}, 100),
	},
}
</script>
