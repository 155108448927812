<template>
	<div
		v-if="headerLayout !== 100"
		:class="headerCss"
		class="ce-header"
	>
		<component
			:is="`h${headerLevel}`"
			v-if="headerLayout >= 0 && headerLayout !== 100"
			:class="headerPosition"
			:data-aos="content_animation"
			data-aos-anchor-placement="center-bottom"
			data-aos-duration="1000"
		>
			<nav-link
				v-if="headerLink"
				:to="headerLink.url"
		>
				<span v-html="header"></span>
			</nav-link>

			<template v-else>
				<span v-html="header"></span>
			</template>
		</component>

		<component
			:is="`h${headerLevel + 1}`"
			v-if="subheader" class="subheader"
			:data-aos="content_animation"
			data-aos-anchor-placement="center-bottom"
			data-aos-duration="1000"
		>
			{{ subheader }}
		</component>

	</div>
</template>

<script>
	import CeHeaderBase from '~typo3/components/content/elements/CeHeader.vue'
	export default {
		extends: CeHeaderBase,
		props: {
			content_animation: {
				type: String,
				required: false,
				default: ''
			}
		},
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	.ce-header {
		&--center {
			text-align: center;
		}

		&--left {
			text-align: left;
		}

		&--right {
			text-align: right;
		}
	}
</style>
