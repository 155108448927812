<template>
	<a
		class="btn btn-secondary"
		v-bind:class="{
			'disabled': isDisabled
		}"
		href="#"
		role="button"
	>
		<slot/>
	</a>
</template>

<script>
export default {
	props: {
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
