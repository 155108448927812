<template>
	<div
		v-if="content_animation"
		class="ce-text"
		:data-aos="content_animation"
		data-aos-anchor-placement="center-bottom"
		data-aos-duration="1000"
	>
		<ce-header v-bind="$props" />
		<html-parser :content="bodytext" />
	</div>
	<div
		v-else
		class="ce-text"
	>
		<ce-header v-bind="$props" />
		<html-parser :content="bodytext" />
	</div>
</template>

<script>
import CeText from '~typo3/components/content/elements/CeText.vue'
export default {
	name: 'CeText',
	extends: CeText,
	props: {
		bodytext: {
			type: String,
			required: true,
			default: ''
		},
		content_animation: {
			type: String,
			required: false,
			default: ''
		}
	}
}
</script>
